import React from 'react'
import componentStyles from './HomeSlideshow.module.scss'

const Slide = ({ image, backgroundImage, alignText, ...props }) => {
  const slideClass = backgroundImage
    ? `${componentStyles.slide} ${componentStyles[backgroundImage]}`
    : componentStyles.slide

  return (
    <div className={slideClass}>
      <div className={componentStyles.overlay}></div>
      <div className={componentStyles.slideContentContainer}>
        {(alignText === 'left' || !alignText) && (
          <div className={componentStyles.imgContainer}>{image}</div>
        )}

        <div className={componentStyles.content}>{props.children}</div>

        {alignText === 'right' && (
          <div className={componentStyles.imgContainer}>{image}</div>
        )}
      </div>
    </div>
  )
}

export default Slide
