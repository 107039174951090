import React, { useEffect, useState } from 'react'
import componentStyles from './HomeSlideshow.module.scss'
import { FormattedMessage } from 'gatsby-plugin-intl'
import { Container, Row, Col } from 'react-grid-system'
import {
  FaAngleLeft,
  FaAngleRight,
  FaPauseCircle,
  FaPlayCircle
} from 'react-icons/fa'
import { IoMdMegaphone } from 'react-icons/io'

const HomeSlideshow = ({ children, ...props }) => {
  const [activeSlide, setActiveSlide] = useState(0)
  const [animating, setAnimating] = useState(true)
  // const[progressPercent, setPercent] = useState(0)
  const slideshowNavHandler = (direction) => {
    if (activeSlide + direction > children.length - 1) setActiveSlide(0)
    else if (activeSlide + direction < 0) setActiveSlide(children.length - 1)
    else setActiveSlide(activeSlide + direction)
  }

  useEffect(() => {
    // let carouselInterval, progressPercent;
    let carouselInterval
    if (animating) {
      carouselInterval = setInterval(() => {
        slideshowNavHandler(1)
      }, 10000)
    }
    return () => {
      clearTimeout(carouselInterval)
      // clearTimeout(progressPercent)
    }
  }, [activeSlide, animating, slideshowNavHandler])

  return (
    <>
      <section className={componentStyles.slideshowContainer}>
        <button
          className={componentStyles.navBtn}
          onClick={() => slideshowNavHandler(-1)}
          aria-label='previous slide'
        >
          <FaAngleLeft />
        </button>

        <div aria-live='polite'>{children[activeSlide]}</div>

        <button
          className={componentStyles.navBtn}
          onClick={() => slideshowNavHandler(1)}
          aria-label='next slide'
        >
          <FaAngleRight />
        </button>

        {/* <div className={componentStyles.progressOuter}>
                    <div className={componentStyles.progressInner}></div>
                </div> */}
      </section>

      <div className={componentStyles.navBar}>
        <button
          onClick={() => setAnimating(!animating)}
          aria-label={animating ? 'pause slideshow' : 'play slideshow'}
        >
          {animating ? <FaPauseCircle /> : <FaPlayCircle />}
        </button>
        <ul role='presentation'>
          {children.map((item, i) => (
            <li role='none' key={i}>
              <button
                role='menuitem'
                key={i}
                className={activeSlide === i ? componentStyles.active : ''}
                onClick={(e) => {
                  setActiveSlide(i)
                }}
                aria-label={`Go to slide ${i}`}
              ></button>
            </li>
          ))}
        </ul>
      </div>
    </>
  )
}

export default HomeSlideshow
