import React from 'react'
import { useMediaQuery } from 'react-responsive'
import '../../styles/App.scss'

import Nav from '../Nav/Nav'
import NavMobile from '../Nav/NavMobile'
import PageHeader from '../PageHeader/PageHeader'
import PageFooter from '../PageFooter/PageFooter'

const HomeLayout = ({
  header,
  subheader,
  breadcrumb,
  view,
  children,
  ...props
}) => {
  const isScreenSm = useMediaQuery({ maxWidth: 1200 })

  return (
    <>
      {isScreenSm ? <NavMobile view={view} /> : <Nav view={view} />}

      {header && (
        <PageHeader
          header={header}
          subheader={subheader}
          breadcrumb={breadcrumb}
        />
      )}
      <main className='homePageBody'>{children}</main>
      <PageFooter />
    </>
  )
}

export default HomeLayout
