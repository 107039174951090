import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { useIntl, FormattedMessage } from 'gatsby-plugin-intl'
import Img from 'gatsby-image'
// import { Container, Row, Col } from "react-grid-system";

import HomeLayout from '../components/Layouts/HomeLayout'
import HomeSlideshow from '../components/HomeSlideshow/HomeSlideshow'
import Slide from '../components/HomeSlideshow/Slide'
import SEO from '../components/seo'
import Button from '../components/Button/Button'

const IndexPage = () => {
  const intl = useIntl()
  const data = useStaticQuery(graphql`
    query {
      slide1: file(relativePath: { eq: "lati_cutOut.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      slide2: file(relativePath: { eq: "napier_cutOut.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      slide3: file(relativePath: { eq: "montague_cutOut.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      slide4: file(relativePath: { eq: "kayema_cutOut.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      slide5: file(relativePath: { eq: "builder02_cutOut.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <HomeLayout>
      <SEO
        description={intl.formatMessage({ id: 'metaTags.homeDescription' })}
        lang={intl.locale}
        title={intl.formatMessage({ id: 'nav.home' })}
      />

      <h1 style={{ height: `0`, overflow: `hidden`, margin: `0` }}>
        <FormattedMessage id='nav.hcra' />
      </h1>

      <HomeSlideshow>
        <Slide
          image={
            <Img
              fluid={data.slide1.childImageSharp.fluid}
              alt=''
              style={{ maxHeight: '100%' }}
              imgStyle={{ objectFit: 'contain' }}
            />
          }
          alignText='right'
        >
          <h2>
            <span className='thin'>
              <FormattedMessage id='slides.01titleA' />
            </span>
            <br />
            <FormattedMessage id='slides.01titleB' />
            <br />
            <FormattedMessage id='slides.01titleC' />
          </h2>
          <Button
            id='aboutLink'
            outline
            altColor
            linkPath='/about/our-role/'
            text={<FormattedMessage id='slides.01btn' />}
          />
        </Slide>

        <Slide
          image={
            <Img
              fluid={data.slide2.childImageSharp.fluid}
              alt=''
              style={{ maxHeight: '100%' }}
              imgStyle={{ objectFit: 'contain' }}
            />
          }
        >
          <h2>
            <span className='thin'>
              <FormattedMessage id='slides.06titleA' />
            </span>
            <br />
            <FormattedMessage id='slides.06titleB' />
          </h2>
          <Button
            id='obdLink'
            linkExternal
            outline
            altColor
            linkPath='https://obd.hcraontario.ca'
            text={<FormattedMessage id='slides.06btn' />}
          />
        </Slide>

        <Slide
          image={
            <Img
              fluid={data.slide5.childImageSharp.fluid}
              alt=''
              style={{ maxHeight: '100%' }}
              imgStyle={{ objectFit: 'contain' }}
            />
          }
        >
          <h2>
            <FormattedMessage id='slides.04titleA' />
            <br />
            <span className='thin'>
              <FormattedMessage id='slides.04titleB' />
            </span>
          </h2>
          <Button
            id='faqLink'
            outline
            altColor
            linkPath='/builder-vendor/faq/'
            text={<FormattedMessage id='slides.03btn' />}
          />
        </Slide>

        <Slide
          image={
            <Img
              fluid={data.slide3.childImageSharp.fluid}
              alt=''
              style={{ maxHeight: '100%' }}
              imgStyle={{ objectFit: 'contain' }}
            />
          }
          alignText='right'
        >
          <h2 className=''>
            <span className='thin'>
              <FormattedMessage id='slides.03titleA' />
            </span>
            <br />
            <FormattedMessage id='slides.03titleB' />
            <br />
            <span className='thin'>
              <FormattedMessage id='slides.03titleC' />
            </span>
          </h2>
          <Button
            id='aboutLink'
            outline
            altColor
            linkPath='/builder-vendor/become-a-licensed-builder/do-i-need-a-licence/'
            text={<FormattedMessage id='slides.03btn' />}
            aria-label={intl.formatMessage({ id: 'builderNav.dashboard' })}
          />
        </Slide>

        <Slide
          image={
            <Img
              fluid={data.slide4.childImageSharp.fluid}
              alt=''
              style={{ maxHeight: '100%' }}
              imgStyle={{ objectFit: 'contain' }}
            />
          }
        >
          <h2 className=''>
            <span className='thin'>
              <FormattedMessage id='nav.buyerDashA' />
            </span>
            <br />
            <FormattedMessage id='nav.buyerDashB' />
          </h2>
          <Button
            id='newHomeBuyerLink'
            outline
            altColor
            linkPath='/new-home-buyer/dashboard/'
            text={<FormattedMessage id='slides.03btn' />}
            aria-label={intl.formatMessage({ id: 'buyerNav.dashboard' })}
          />
        </Slide>
      </HomeSlideshow>
    </HomeLayout>
  )
}

export default IndexPage
